import React from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import Group from 'components/utils/Group'
import moment from 'moment'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { INTERVALS } from '../RequestAgentCallbackDateTimeInput'

export function RequestAgentCallbackConfirmationModal(results) {
  const { callbackTime, callbackDate, email } = results.customer
  return (
    <ModalBase>
      <form>
        <ModalHeader title="Callback request sent" />
        <ModalBody>
          <ModalContent>
            <VerticalSpacer gap={20}>
              <Group direction="vertical" gap={20}>
                <BodyTextBlock variant="large">
                  A customer service agent will call you at
                </BodyTextBlock>
                <Group direction="vertical" gap={0}>
                  <BodyTextBlock variant="large" weight="bold">
                    Time: {INTERVALS.find(interval => interval.start === callbackTime)?.key}
                  </BodyTextBlock>
                  <BodyTextBlock variant="large" weight="bold">
                    Date: {moment(callbackDate).format(DMY_CASUAL_FORMAT)}
                  </BodyTextBlock>
                </Group>
                <BodyTextBlock variant="large">
                  A confirmation email has been sent to {email}.
                </BodyTextBlock>
              </Group>
            </VerticalSpacer>
          </ModalContent>
        </ModalBody>
        <ModalFooter primaryActionProps={{ children: 'Close' }}/>
      </form>
    </ModalBase>
  )
}
